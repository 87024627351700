/*Set global typography*/
div, span, p, blockquote, button, pre, 
a, abbr, acronym, address, code, input, textarea, ol, 
ul, fieldset, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figcaption, summary {
  line-height: var(--paragraph-line-height);
  font-size: var(--base-font-size);
  font-family: var(--paragraph-font);
  margin-bottom: var(--paragraph-margin);
  color: var(--text-color);
  font-weight: var(--paragraph-weight)
}

.small, small {
  font-size: var(--small-font-size);
  * {
    font-size: var(--small-font-size);
  }
}

.big, big {
  font-size: var(--big-font-size);
  * {
    font-size: var(--big-font-size);
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.11em;
  font-family: var(--heading-font);
  margin-bottom: .5em;
  font-weight: var(--heading-weight);
  font-size: var(--heading3-size);
  color: var(--text-color)
}

h1 {
  font-size: var(--heading1-size);
  font-weight: var(--heading-weight)
}

h2 {
  font-size: var(--heading2-size);
}

h4 {
  font-size: var(--heading4-size);
}

a {
  color: var(--link-color);
  text-decoration: none;
}

.no-decoration {
  color: var(--text-color);
  text-decoration: none;
  & > * {
    color: var(--text-color);
    text-decoration: none;
  }
}

b {
  font-weight: bold
}

i {
  font-style: italic;
}
