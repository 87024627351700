:root{
  --black: #0e0d0d;
  --white: #ffffff;
  --lightgray: #f0f0f0;
  --gray: #eeebeb;

  // Text Colors
  --text-color: var(--black);
  --link-color: rgb(149, 143, 216);
  --link-underline: var(--black);

  // Breakpoints
  $breakpoint-mobile: 50rem;

  // Element Colours
  --footer-bg: var(--gray);
  --footer-text-color: inherit;

  --loader-background: var(--black);
  --loader-text-color: var(--white);

  --background: var(--lightgray);
  --background-dark: var(--black);
  
  --base-font-size: clamp(1.11rem, 1.1vw, 1.35rem);
  --small-font-size: clamp(.9rem, .3vw, 3rem);
  --big-font-size: clamp(1.4rem, 1.1vw, 1.6rem);;
  --paragraph-font: 'Space Grotesk', Helvetica, sans-serif;
  --paragraph-margin: 1em;
  --paragraph-weight: normal;
  --paragraph-line-height: 1.48em;

  --heading-font: var(--paragraph-font);
  --heading-weight: 500;
  --heading1-size: clamp(2.3rem, 3vw, 3.75rem);
  --heading2-size: clamp(2rem, 1vw, 6rem);
  --heading3-size: var(--base-font-size);
  --heading4-size: var(--small-font-size);

  --wrapper-width: min(90vw, 50rem);
  --wide-wrapper-width: min(90vw,120rem);

  --navigation-padding: max(1em, 1vw);
}