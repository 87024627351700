@font-face {
    font-family: "Space Grotesk";
    src: url("../assets/fonts/space/SpaceGrotesk-Regular.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../assets/fonts/space/SpaceGrotesk-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
  