.container {
    max-width: var(--wrapper-width);
    margin: auto;
}

.wide-container {
    max-width: var(--wide-wrapper-width);
    margin: auto;
    display: block;
}

@media (max-width: var(--breakpoint-mobile)) {
    .wide-container {
        max-width: 95vw;
    }
}

body {
    background: var(--background);
}

.no-spacing {
    margin: 0;
    padding: 0;
    & * {
        margin: 0;
        padding: 0;
        height: 100%;
    }
}

.no-margins {
    margin: 0;
    padding: 0;
}